import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import _axios from "../axios";
import buildLink from "../urls";
import { AccountContext } from "../contexts/AccountContext";
import { HandleAuthForm } from "./HandleAuthForm";
import { CartContext } from "../contexts/CartContext";
import Loader from "./Loader";
import { FaCaretDown } from 'react-icons/fa';
import home from "../bottomBarImages/home.png";
import homered from "../bottomBarImages/homered.png";
import categories from "../bottomBarImages/categories.png";
import categoriesRed from "../bottomBarImages/categoriesRed.png";
import wishlist from "../bottomBarImages/wishlist.png";
import wishlistRed from "../bottomBarImages/wishlistRed.png";
import basket from "../bottomBarImages/basket.png";
import basketRed from "../bottomBarImages/basketRed.png";
import profile from "../bottomBarImages/profile.png";
import profileRed from "../bottomBarImages/profileRed.png";
import { WishlistContext } from "../contexts/WishlistContext"; // Import WishlistContext

const BottomBar = () => {
  const wrapperRef = useRef(null);
  const [sellerId, setSellerId] = useState("0");
  const [accountState, accountDispatch] = useContext(AccountContext);
  const [cartState] = useContext(CartContext);
  const [wishlistState, setWishlistState] = useState({ items_length: 0 });
  const navigate = useNavigate();
  const location = useLocation();
  const { openAuthForm } = HandleAuthForm();
  const [stateWishlist, dispatchWish] = useContext(WishlistContext);

  // Fetch wishlist count on component mount
  useEffect(() => {
    if (accountState.loged) {
      _axios.get("https://www.ishtari.com.gh/v2/index.php?route=account/wishlist/getTotalWishlist")
        .then(response => {
          setWishlistState(response.data.data);
        })
        .catch(error => {
          console.error("Error fetching wishlist data", error);
        });
    }
  }, [accountState.loged]);

  function checkLogin() {
    accountDispatch({ type: "setLoading", payload: true });
    _axios.get(buildLink("login", undefined, window.innerWidth)).then((response) => {
      const data = response.data;
      accountDispatch({ type: "setShowOver", payload: false });
      if (data.customer_id > 0) {
        accountDispatch({ type: "setLoged", payload: true });
        accountDispatch({ type: "setUsername", payload: data.username });
        accountDispatch({ type: "setEmail", payload: data.email });
      } else {
        accountDispatch({ type: "setLoged", payload: false });
      }
      if (data.seller_logged !== "0") {
        setSellerId(Number(data.seller_logged));
      }
      accountDispatch({ type: "setLoading", payload: false });
    });
  }

  const cartLoading = cartState.loading || false;
  const cartCount = cartState.productsCount || 0;

  return (
    <div className="block md:hidden flex justify-around items-center fixed bottom-0 w-full bg-white p-2 z-50">
      <Link
        to="/"
        className={`flex flex-col items-center ${location.pathname === "/" ? "text-blue-500" : "text-gray-700"}`}
      >
        <img
          src={location.pathname === "/" ? homered : home}
          alt="Home"
          className="w-5 h-5"
        />
        <span className="text-xs font-medium">Home</span>
      </Link>
      <Link
        to="/allcategories"
        className={`flex flex-col items-center ${location.pathname === "/allcategories" ? "text-blue-500" : "text-gray-700"}`}
      >
        <img
          src={location.pathname === "/allcategories" ? categoriesRed : categories}
          alt="categories"
          className="w-5 h-5"
        />
        <span className="text-xs font-medium">Categories</span>
      </Link>

      {accountState.loged && (
        <Link
          to="/account/wishlist"
          className={`flex flex-col justify-center text-center ${location.pathname === "/account/wishlist" ? "text-dbase" : "text-dblack"} relative`}
        >
          <img
            src={location.pathname === "/account/wishlist" ? wishlistRed : wishlist}
            alt="Wishlist"
            className="mx-auto w-5 h-5"
          />
          <span className="text-sm font-medium">Wish</span>
          <span
            className={`w-5 h-5 bg-dbase flex text-white items-center justify-center rounded-full text-xs absolute right-0 sm:right-2 -top-1.5 border-2 border-white`}
          >
            <span>
              {accountState.loading ? (
                <Loader className="h-4 w-4 text-white" />
              ) : (
                <span>{wishlistState.total}</span>
              )}
            </span>
          </span>
        </Link>
      )}

      <Link
        to="/cart"
        className={`relative flex flex-col justify-center text-center ${
          location.pathname === "/cart" ? "text-blue-500" : "text-gray-700"
        }`}
      >
        {location.pathname === "/cart" ? (
          <img
            src={basketRed}
            alt="Basket"
            width={20}
            height={20}
            className="mx-auto"
          />
        ) : (
          <img
            src={basket}
            alt="Basket"
            width={20}
            height={20}
            className="mx-auto"
          />
        )}
        <span className="text-xs font-medium">Basket</span>
        <span
          className="w-5 h-5 bg-blue-500 flex bg-dbase text-white items-center justify-center rounded-full text-xs absolute right-0 -top-1.5 border-2 border-white"
        >
          <span>
            {cartLoading ? (
              <Loader styles="h-4 w-4 text-white" />
            ) : (
              <span>{cartCount}</span>
            )}
          </span>
        </span>
      </Link>

      <div
        onClick={() => {
          if (accountState.loged) {
            navigate('/account/profile');
          } else {
            accountDispatch({ type: "setShowListAcc", payload: false });
            accountDispatch({ type: "setisRegistrationFormOpen", payload: true });
            openAuthForm();
            localStorage.setItem('checkoutLogin', false);
          }
        }}
        className={`flex flex-col items-center text-center cursor-pointer hover:opacity-80 ${
          location.pathname !== "/account/wishlist" &&
          location.pathname.startsWith("/account/profile")
            ? "text-blue-500"
            : "text-gray-700"
        }`}
      >
        {location.pathname === "/account/profile" ? (
          <img
            src={profileRed}
            alt="Profile"
            width={20}
            height={20}
            className="mx-auto"
          />
        ) : (
          <img
            src={profile}
            alt="Profile"
            width={20}
            height={20}
            className="mx-auto"
          />
        )}
        <span className="text-xs font-medium">Account</span>
      </div>
    </div>
  );
};

export default BottomBar;
