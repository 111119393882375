import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import _axios from "../axios";
import { AccountContext } from "../contexts/AccountContext";
import buildLink, { path } from "../urls";
import DOMPurify from "dompurify";

function FooterV2({ width }) {
  const [data, setData] = useState();
  const [state] = useContext(AccountContext);
  const [show, setShow] = useState({ id: "", status: false });
  async function getFooter() {
    const footer = await _axios.get(
      buildLink("footerv2", undefined, window.innerWidth)
    );
    setData(footer.data.data);
  }
  useEffect(() => {
    getFooter();
  }, []);
  function toggleButton(id) {
    if (show.id === id) {
      setShow({ id: "", status: false });
    } else {
      setShow({ id: id, status: true });
    }
  }
  return (
    <div className="border-t-8 border-dinputBorder">
      {width > 650 ? (
        <div className={`flex justify-between mt-5 ${width > 1170 ? 'px-16' : 'px-8'} text-dblack`}>
          {data?.data?.map((cat) => {
            return (
              <div key={cat.category_id} className="mt-2 pb-3">
                <div className="pb-3">
                  <Link
                    className="font-bold text-d15 uppercase hover:text-dblue"
                    to={`${
                      // state.admin
                      //   ? path + "/category/" + cat.category_id
                      //   :
                         cat.name.length > 0
                        ? "/" +
                          cat.name
                            .replace(/\s+&amp;\s+|\s+&gt;\s+/g, "-").replaceAll('%', '')
                            .replace(/\s+/g, "-") +
                          "/c=" +
                          cat.category_id
                        : "cat/c=" + cat.category_id
                    }`}
                    // to={`/${cat.name}/c=${cat.category_id}`}
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(cat.name) }}
                  />
                </div>
                {cat?.data?.map((sub) => {
                  return (
                    <div
                      key={sub.category_id}
                      className="flex font-light text-d13 py-1"
                    >
                      {sub.category_id === "1697" ? (
                        <Link
                          className="text-d13 hover:text-dblue"
                          to={`${
                            // state.admin
                            //   ? path + "/category/" + sub.category_id
                            //   :
                               sub.name.length > 0
                              ? "/" +
                                sub.name
                                  .replace(/\s+&amp;\s+|\s+&gt;\s+/g, "-").replaceAll('%', '')
                                  .replace(/\s+/g, "-") +
                                "/c=" +
                                sub.category_id
                              : "cat/c=" + sub.category_id
                          }`}
                          // to={`/${sub.name}/c=${sub.category_id}`}
                        >
                          {/* <img src={}  className="w-5 h-5" alt="24-hr-deals"/> */}
                          <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(sub.name) }} />
                        </Link>
                      ) : (
                        <Link
                          className="text-d13 hover:text-dblue"
                          to={`${
                            // state.admin
                            //   ? path + "/category/" + sub.category_id
                            //   :
                               sub.name.length > 0
                              ? "/" +
                                sub.name
                                  .replace(/\s+&amp;\s+|\s+&gt;\s+/g, "-").replaceAll('%', '')
                                  .replace(/\s+/g, "-") +
                                "/c=" +
                                sub.category_id
                              : "cat/c=" + sub.category_id
                          }`}
                          // to={`/${sub.name}/c=${sub.category_id}`}
                          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(sub.name) }}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            );
          })}

          <div className="mt-2 pb-3">
            <div className={!window.config["showTopBrand"] ? "hidden" : "pb-3"}>
              <div
                className="font-bold uppercase text-d15 hover:text-dblue"
                dangerouslySetInnerHTML={{ __html: "TOP BRAND" }}
              />
            </div>
            {data?.brand?.map((brand) => {
              return (
                <div key={brand.id} className="flex font-light text-d13 py-1">
                  <Link
                    className="text-d13 hover:text-dblue"
                    to={`${
                      // state.admin
                      //   ? path + "/manufacturer/" + brand.id
                      //   :
                         brand.name.length > 0
                        ? "/" +
                          brand.name
                            .replace(/\s+&amp;\s+|\s+&gt;\s+/g, "-").replaceAll('%', '')
                            .replace(/\s+/g, "-") +
                          "/m=" +
                          brand.id
                        : "cat/m=" + brand.id
                    }`}
                    // to={`${brand.name}/c=${brand.id}`}
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(brand.name) }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div className="mt-2">
          {data?.data?.map((cat) => {
            return (
              <div
                key={cat.category_id}
                className="flex-row container justify-between"
              >
                <div className="mt-1 border-b  border-dinputBorder ">
                  <div className="flex items-center mx-4 justify-between font-normal text-sm pb-1 hover:text-dblue">
                    <Link
                      to={`${
                        // state.admin
                        //   ? path + "/category/" + cat.category_id
                        //   : 
                          cat.name.length > 0
                          ? "/" +
                            cat.name
                              .replace(/\s+&amp;\s+|\s+&gt;\s+/g, "-").replaceAll('%', '')
                              .replace(/\s+/g, "-") +
                            "/c=" +
                            cat.category_id
                          : "cat/c=" + cat.category_id
                      }`}
                      // to={`/${cat.name}/c=${cat.category_id}`}
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(cat.name),
                      }}
                    ></Link>
                    <span
                      onClick={() => toggleButton(cat.category_id)}
                      className={
                        show.id === cat.category_id
                          ? " text-dgrey1 font-thin text-2xl transition-all "
                          : " text-dgrey1 font-thin text-2xl transition-all "
                      }
                    >
                      {show.id === cat.category_id ? "-" : "+"}
                    </span>
                  </div>
                  <div
                    className={
                      show.id === cat.category_id && show.status
                        ? "flex-row mx-4"
                        : "hidden"
                    }
                  >
                    {cat.data.map((sub) => (
                      <Link
                        key={sub.category_id}
                        className="block font-light text-d13 py-1 hover:text-dblue"
                        to={`${
                          // state.admin
                          //   ? path + "/category/" + sub.category_id
                          //   :
                            
                            sub.name.length > 0
                            ? "/" +
                              sub.name
                                .replace(/\s+&amp;\s+|\s+&gt;\s+/g, "-").replaceAll('%', '')
                                .replace(/\s+/g, "-") +
                              "/c=" +
                              sub.category_id
                            : "cat/c=" + sub.category_id
                        }`}
                        // to={`${path}/${sub.name}/c=${sub.id}}`}
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(sub.name),
                        }}
                      ></Link>
                    ))}
                  </div>
                </div>
              </div>
            );
          })}
          <div>
            <div className="flex-row container justify-between">
              <div className="mt-2 border-b pb-2 border-dinputBorder ">
                <div
                  onClick={() => toggleButton("tb")}
                  className="flex items-center mx-4 justify-between font-normal text-sm hover:text-dblue"
                >
                  <p
                    dangerouslySetInnerHTML={{
                      __html: `Top Brand`,
                    }}
                  ></p>
                  <span
                    className={
                      show.id === "tb"
                        ? "font-thin text-dgrey1 text-2xl transition-all"
                        : "font-thin text-dgrey1 text-2xl transition-all"
                    }
                  >{show.id === "tb" ? '-' : '+'}</span>
                </div>
                <div
                  className={
                    show.id === "tb" && show.status ? "flex-row mx-4" : "hidden"
                  }
                >
                  {data?.brand?.map((brand) => (
                    <Link
                      key={brand.name}
                      className="block font-light text-d13 py-1 hover:text-dblue"
                      to={`${
                        // state.admin
                        //   ? path + "/manufacturer/" + brand.id
                        //   :
                           brand.name.length > 0
                          ? "/" +
                            brand.name
                              .replace(/\s+&amp;\s+|\s+&gt;\s+/g, "-").replaceAll('%', '')
                              .replace(/\s+/g, "-") +
                            "/m=" +
                            brand.id
                          : "cat/m=" + brand.id
                      }`}
                      // to={`${path}/${sub.name}/c=${sub.id}}`}
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(brand.name),
                      }}
                    ></Link>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default FooterV2;
