import { useState, useEffect, useCallback, useRef, useContext, useMemo } from "react";
import _axios from "../axios";
import PointsLoader from "../components/PointsLoader";
import SeoHandler from "../components/SeoHandler";
import WidgetsLoop from "../components/WidgetsLoop";
import { AccountContext } from "../contexts/AccountContext";
import buildLink from "../urls";
import { useWindowDimensions } from "../components/TopHeader";
import download from "../assets/images/download.gif";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { CurrencyContext } from "../contexts/CurrencyContext";
import ScrollToTop from "react-scroll-to-top";
import { AiOutlineRadiusBottomright } from "react-icons/ai";

function Home() {
  const { width } = useWindowDimensions();
  const history = useNavigate();
  const [state] = useContext(AccountContext);
  const curr = useContext(CurrencyContext);
  const seoData = {
    title: window.config["seo-title"],
    description: window.config["description"]
  };

  const oldWidth = useRef("");

  // function handleScroll() {
  //     if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight) return;
  //     setIsFetching(true)
  //   }
  // useEffect(() => {
  //     window.addEventListener('scroll',handleScroll)
  //     return () => window.removeEventListener('scroll',handleScroll)
  // },[])
  
  const [loading, setLoading] = useState(true);
  const [likedData, setLikedData] = useState([]);


  const dataRef = useRef([]);
  const [hasMore, setIsHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  const sentinelRef = useRef(null);
  const observer = useRef(null);
  // const { showStats } = useMarketingData();
  const [bannerStats, setBannerStats] = useState([]);

  const lastElementRef = useCallback(
    (node) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      
      observer.current = new IntersectionObserver((entries) => {
        if (
          entries[0].isIntersecting &&
          entries[0].intersectionRatio > 0 &&
          entries[0].intersectionRatio < 1 &&
          hasMore
        ) {
          setPage((prevPage) => prevPage + 1);
          // setIsLoading(true);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoading, hasMore]
  );

  const WidgetsList = useMemo(() => {
    return dataRef.current.map((widget, index) => {
      if (dataRef.current.length === index + 1) {
        return (
          <div
            className="theHome bg-white "
            ref={lastElementRef}
            key={widget.mobile_widget_id}
          >
            {" "}
            <WidgetsLoop widget={widget} bannerStats={bannerStats} />{" "}
          </div>
        );
      } else {
        return (
          <div className=" bg-white" key={widget.mobile_widget_id}>
            <WidgetsLoop widget={widget} bannerStats={bannerStats} />{" "}
          </div>
        );
      }
    });
  }, [lastElementRef, bannerStats]);


  useEffect(() => {
    // if (page > 1) {
      setIsLoading(true);
      var obj = {
      view: window.innerWidth > 650 ? "web_desktop" : "web_mobile",
      limit: 10,
      page: page,
    };
    let link =
      buildLink("home", undefined, undefined, window.config["site-url"]) +
      "&source_id=1";
    _axios.post(link, obj).then((response) => {
      if (response?.data?.success) {
        const newData = response?.data?.data?.widgets;
        // setData((prevData) => [...prevData, ...newData]);

        // setData((prevWidgets) => {
        //   return [
        //     ...new Set([...prevWidgets, ...response?.data?.data?.widgets]),
        //   ];
        // });
        dataRef.current = [
          ...dataRef.current,
          ...response?.data?.data?.widgets,
        ];

        if (
          page === 1 &&
          response?.data?.data?.widgets[0].cover_header === "1"
        ) {
          //setHeaderColor("#0000FF");
          // setHeaderColor(response?.data?.data?.widgets[0].background_color);
        }

        // setTimeout(() => {
        //   setInitialLoading(false)
        // }, 200);

        if (page >= response?.data?.data?.meta?.total_pages)
          setIsHasMore(false);
        else setIsHasMore(true);
        setIsLoading(false);
      }
    });
    // }

    // setIsLoading(false);
  }, [page]);
  // const lastElementRef = useCallback(
  //   (node) => {
  //     if (loading) return;
  //     if (observer.current) observer.current.disconnect();
  //     observer.current = new IntersectionObserver((entries) => {
  //       if (entries[0].isIntersecting && hasMore) {
  //         setPage((prevPage) => prevPage + 1);
  //       }
  //     });
  //     if (node) observer.current.observe(node);
  //   },
  //   [loading, hasMore]
  // );

  oldWidth.current = width;
  //useEffect(() => {
  async function changeCurrency() {
    window.addEventListener("resize", function () {
      if (
        Number(oldWidth.current + 50) < Number(width) ||
        Number(oldWidth.current) > Number(width + 50)
      ) {
        this.window.location.reload();
      }
    });

    const condition = curr?.data?.allow_change_currency_in_website ?? "false";
    // console.log(localStorage.getItem("currency"))
    // if (
    //    (window.config["site-url"] === "https://www.ishtari.com" ||
    //     localStorage.getItem("site-local-name") === "ishtari")
    //     // && (localStorage?.getItem('currency') !== "USD")
    // ) {

    //   var obj = {
    //     currency: "USD",
    //   };
    //   await _axios.post(
    //     buildLink("currency", undefined, window.innerWidth),
    //     obj
    //   ).then((response) => {
    //       const data = response.data;

    //       if (data.success === true) {
    //         localStorage.setItem("currency", "USD");
    //       }

    //     });

    // } else if(
    //   (window.config["site-url"] === "https://www.ishtari.com.gh" ||
    //   localStorage.getItem("site-local-name") === "ishtari-ghana")
    // ){

    //   var obj = {
    //     currency: "GHS",
    //   };
    //   await _axios.post(
    //     buildLink("currency", undefined, window.innerWidth),
    //     obj
    //   ).then((response) => {
    //       const data = response.data;

    //       if (data.success === true) {
    //         localStorage.setItem("currency", "GHS");
    //       }
    //     });
    // }

    // if (width > 650) {
    // getData();
    // } else {

    //   getMData();
    // }
  }

  // useEffect(() => changeCurrency(), [width, page]);

  // }, [width, page]);
  // State
  // const [widgets, setWidgets] = useState([]);
  // useEffect(()=>{

  //   if (
  //   // (localStorage.getItem("currency") !== "USD" ) &&
  //     (window.config["site-url"] === "https://www.ishtari.com" ||
  //       localStorage.getItem("site-local-name") === "ishtari")
  //   ) {
  //     var obj = {
  //       currency: "USD"
  //     };
  //     _axios
  //       .post(buildLink("currency", undefined, window.innerWidth), obj)
  //       .then((response) => {
  //         const data = response.data;

  //         if (data.success === true) {
  //           localStorage.setItem("currency", "USD");
  //         }
  //       });
  //   }
  // },[window.Location])
  // Get home data for desktop
  // async function getData() {
  //   setLoading(true);
  //   //  var plusHome = state.admin ?  '&nocahe=true' : ''
  //   await _axios({
  //     method: "post",
  //     url: buildLink("home", undefined, undefined),
  //     data: {
  //       view: width > 650 ? "web_desktop" : "web_mobile",
  //       limit: 10,
  //       page: page
  //     }
  //   })
  //     .then((response) => {
  //       // alert(response?.data?.success)
  //       if (response?.data?.success) {
  //         setWidgets((prevWidgets) => {
  //           return [
  //             ...new Set([...prevWidgets, ...response?.data?.data?.widgets])
  //           ];
  //         });
  //       }

  //       setLoading(false);
  //       // console.log( response?.data?.data?.meta?.total_pages)
  //       //  if( width > 650) {
  //       if (page >= response?.data?.data?.meta?.total_pages)
  //         setIsHasMore(false);
  //       else setIsHasMore(true);
  //       //  }else{
  //       //   if (page === response?.data?.data.meta.total_pages) setIsHasMore(false);
  //       //   else setIsHasMore(true);

  //       //  }
  //     })
  //     .catch((e) => {});
  // }
  // Get home data for Mobile
  // async function getData() {
  //   // var plusHome = state.admin ? "&nocahe=true" : "";

  //   setLoading(true);
  //   await _axios({
  //     method: "post",
  //     url: buildLink("home", undefined, window.innerWidth),
  //     data: { view: width > 650 ?  "web_desktop" : "web_mobile" , limit: 20 }
  //   }).then((response) => {
  //     const data = response?.data?.data;
  //     setWidgets(data);
  //     setLoading(false);
  //   });
  // }

  // useEffect(() => {
  //   _axios
  //     .get(buildLink("getLikeProduct", undefined, window.innerWidth))
  //     .then((response) => {
  //       if (response.data.success) {
  //         setLikedData(response.data.data);
  //       }
  //     });
  // }, []);

  //page view conversion google ads
  useEffect(() => {
    if (!state.admin) {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }

      if (window.location.host === "www.ishtari.com") {
        gtag("event", "conversion", {
          send_to: "AW-991347483/pc3dCIaww44YEJuG29gD"
        });
      } else if (window.location.host === "www.ishtari.com.gh") {
        gtag("event", "conversion", {
          send_to: "AW-10993907106/31DICLmKppEYEKLrpvoo"
        });
      }
    }
  }, []);

  return (
    <>
      <SeoHandler data={seoData} />
      {width < 650 &&
        window.config["site-url"] === "https://www.ishtari.com" && (
          <img
            style={{ marginTop: "-1px" }}
            src={download}
            alt="download-app"
            className="cursor-pointer"
            onClick={() =>
              (window.location.href = "https://www.ishtari.com/app")
            }
          />
        )}

      {width < 650 &&
        window.config["site-url"] === "https://www.ishtari.com.gh" && (
          <img
            style={{ marginTop: "-1px" }}
            src={download}
            alt="download-app"
            className="cursor-pointer"
            onClick={() =>
              (window.location.href = "https://www.ishtari.com.gh/app")
            }
          />
        )}
      <div className="container">
        {width < 650 && (
          <ScrollToTop
            smooth
            className="rounded-full  bg-dgreyBlack text-white text-center opacity-70"
            width="36"
            height="30"
            color="white"
            top="1000"
            style={{ width: "50px", height: "50px", padding: "7px" }}
          />
        )}
        {/* {widgets.map((widget, index) => {
          if (widgets.length === index + 1) {
            return (
              <div
                className="theHome"
                ref={lastElementRef}
                key={widget.mobile_widget_id}
              >
                {" "}
                <WidgetsLoop likedData={likedData} widget={widget} />
              </div>
            );
          } else {
            return (
              <div className="" key={widget.mobile_widget_id}>
                <WidgetsLoop likedData={likedData} widget={widget} />
              </div>
            );
          }
        })} */}
         {WidgetsList}

        {loading && <PointsLoader />}
      </div>
    </>
  );
}

export default Home;
