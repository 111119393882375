import React, { useEffect, useState } from 'react';
import _axios from '../axios';
import PointsLoader from '../components/PointsLoader'; 
import { sanitizeHTML } from '../components/Utils'; 
import { useNavigate } from 'react-router-dom'; 
import buildLink from "../urls"; 

const AllCategories = () => {
  const navigate = useNavigate(); // React Router hook for navigation
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    _axios
      .get(buildLink("all_categories", undefined, window.innerWidth) + "&source_id=1")
      .then((response) => {
        setLoading(false);
        setCategories(response.data.data); 
      })
      .catch(() => setLoading(false));
  }, []);

  const buildCategoryUrl = (name, categoryId) => {
    const slug = name
      .replace(/\s+&amp;\s+|\s+&gt;\s+/g, '-') 
      .replace(/\s+/g, '-'); 

    return slug.length > 0
      ? `/${slug}/c=${categoryId}`
      : `cat/c=${categoryId}`;
  };

  return loading ? (
    <PointsLoader />
  ) : (
    <div className="w-full relative">
      <div className="flex flex-row gap-1">
        {categories && (
          <div className="w-[7rem] pb-10 overflow-x-hidden text-center bg-dplaceHolder max-h-screen overflow-y-auto relative flex flex-col">
            {categories.map((category) => (
              <button
                key={category.category_id}
                onClick={() => navigate(buildCategoryUrl(category.name, category.category_id))} // Use dynamic link
                className="text-center px-2 py-4 w-full h-full text-sm text-dblack"
                dangerouslySetInnerHTML={{
                  __html: sanitizeHTML(category.name),
                }}
              ></button>
            ))}
          </div>
        )}

        <div className="max-h-screen pb-10 overflow-x-hidden no-scrollbar overflow-y-auto w-full">
          <div className="flex flex-col gap-5">
            <h2 className="px-2 font-extrabold text-sm">
              Categories
            </h2>
            <div className="grid grid-cols-3 gap-[0.20rem]">
              {categories &&
                categories.map((category) => (
                  <div
                    key={category.category_id}
                    onClick={() => navigate(buildCategoryUrl(category.name, category.category_id))} // Use dynamic link
                    className="h-fit w-26 relative flex flex-col text-center justify-start"
                  >
                    <div className="h-full p-3 w-full">
                      <img
                        className=""
                        src={category.image}
                        loading="lazy"
                        alt={category.name}
                      />
                    </div>
                    <div
                      className="text-sm text-dblack"
                      dangerouslySetInnerHTML={{
                        __html: sanitizeHTML(category.name),
                      }}
                    ></div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllCategories;
