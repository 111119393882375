import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import _axios from "../axios";
import { AccountContext } from "../contexts/AccountContext";
import buildLink, { path } from "../urls";
import arrowRight from "../assets/images/arrow-right.svg";
import { BsFillCartCheckFill, BsStarFill } from "react-icons/bs";
import { RiCoupon3Fill, RiUserFollowLine } from "react-icons/ri";
import { MdAvTimer, MdFeedback } from "react-icons/md";
import {
  FaCheckCircle,
  FaMoneyBillWave,
  FaTv,
  FaUserAlt,
  FaWallet,
} from "react-icons/fa";
import { HiLightBulb } from "react-icons/hi";
function UserSidebar(props) {
  const [data, setData] = useState(props.data);
  const history = useNavigate();
  const [state] = useContext(AccountContext);
  const [showOrderArrow, setShowOrderArrow] = useState(false);
  const [showAddArrow, setShowAddArrow] = useState(false);
  const [showWishArrow, setShowWishArrow] = useState(false);
  const [showBuyArrow, setShowBuyArrow] = useState(false);
  const [showRecentlyViewedArrow, setShowRecentlyViewedArrow] = useState(false);
  const [showFollowArrow, setShowFollowArrow] = useState(false);
  const [showReviewCenterArrow, setshowReviewCenterArrow] = useState(false);
  const [showProfileArrow, setShowProfileArrow] = useState(false);
  const [showFeedBackArrow, setshowFeedBackArrow] = useState(false);
  const [showsuggestionArrow, setshowsuggestionArrow] = useState(false);

  return true ? (
    <aside
      className="box-content bg-white overflow-x-hidden overflow-y-auto block w-full h-full border-r border-dgrey"
      style={{
        flex: "0 0 auto",
      }}
    >
      <ul className="lg:py-3 py-1 bg-white">
        <li
          onMouseEnter={() => {
            setShowProfileArrow(true);
          }}
          onMouseLeave={() => {
            setShowProfileArrow(false);
          }}
        >
          <Link
            to={`${path}/account/profile`}
            className="xl:px-10 lg:px-8 md:px-6 px-16 flex gap-4 items-center h-12 w-full hover:text-dblackk"
            style={{ color: "rgb(126,133,155)" }}
          >
            {/* <i className="icon icon-user-solid "></i> */}
            <div className="text-xl w-8 block text-dbase">
              <FaUserAlt className="text-d16" />
            </div>
            <span
              className={`flex-1 ${
                props.active === "profile" && "font-semibold underline"
              }`}
              style={{
                color: props.active === "profile" ? "rgb(64,69,83)" : "",
              }}
            >
              Profile
            </span>
            {showProfileArrow && <img className="-mr-1" src={arrowRight} />}{" "}
          </Link>
        </li>

        <li
          onMouseEnter={() => {
            setShowOrderArrow(true);
          }}
          onMouseLeave={() => {
            setShowOrderArrow(false);
          }}
        >
          <Link
            to={`${path}/account/orders`}
            className="xl:px-10 lg:px-8 md:px-6 px-16 flex gap-4 items-center h-12 w-full hover:text-dblackk"
            style={{ color: "rgb(126,133,155)" }}
          >
            {/* <i className="icon icon-dollar text-xl w-8 text-dbase"></i> */}
            <div className="-mr-1 text-xl w-8 text-dbase">
              <FaMoneyBillWave className="text-d16" />
            </div>
            <span
              className={`flex-1 ${
                props.active === "orders" && "font-semibold underline"
              }`}
              style={{
                color: props.active === "orders" ? "rgb(64,69,83)" : "",
              }}
            >
              Orders
            </span>
            {showOrderArrow && (
              <img className="-mr-1" src={arrowRight} alt="" />
            )}{" "}
          </Link>
        </li>

        <li className="group">
          <Link
            to={`${path}/account/memberShip`}
            className="xl:px-10 lg:px-8 md:px-6 px-16  flex gap-4 items-center h-12 w-full hover:text-dblackk"
            style={{ color: "rgb(126,133,155)" }}
          >
            <FaTv className=" mr-3 text-dbase text-d16" />
            <span
              className={`flex-1 ${
                props.active === "memberShip" && "font-semibold underline"
              }`}
              style={{
                color: props.active === "memberShip" ? "rgb(64,69,83)" : "",
              }}
            >
              MemberShip
            </span>
            <img
              className="-mr-1 group-hover:block hidden"
              src={arrowRight}
              alt=""
            />{" "}
          </Link>
        </li>

        <li className="group">
          <Link
            to={`${path}/account/wallet`}
            className="xl:px-10 lg:px-8 md:px-6 px-16  flex gap-4 items-center h-12 w-full hover:text-dblackk"
            style={{ color: "rgb(126,133,155)" }}
          >
            <FaWallet className=" mr-3 text-dbase text-d16" />
            <span
              className={`flex-1 ${
                props.active === "wallet" && "font-semibold underline"
              }`}
              style={{
                color: props.active === "wallet" ? "rgb(64,69,83)" : "",
              }}
            >
              Wallet
            </span>
            <img
              className="-mr-1 group-hover:block hidden"
              src={arrowRight}
              alt=""
            />{" "}
          </Link>
        </li>

        <li className="group">
          <Link
            to={`${path}/account/coupons`}
            className="xl:px-10 lg:px-8 md:px-6 px-16  flex gap-4 items-center h-12 w-full hover:text-dblackk"
            style={{ color: "rgb(126,133,155)" }}
          >
            <RiCoupon3Fill className=" mr-3 text-dbase text-d16" />
            <span
              className={`flex-1 ${
                props.active === "coupons" && "font-semibold underline"
              }`}
              style={{
                color: props.active === "coupons" ? "rgb(64,69,83)" : "",
              }}
            >
              Coupons
            </span>
            <img
              className="-mr-1 group-hover:block hidden"
              src={arrowRight}
              alt=""
            />{" "}
          </Link>
        </li>

        <li className="group">
          <Link
            to={`${path}/account/checkin`}
            className="xl:px-10 lg:px-8 md:px-6 px-16  flex gap-4 items-center h-12 w-full hover:text-dblackk"
            style={{ color: "rgb(126,133,155)" }}
          >
            <FaCheckCircle className=" mr-3 text-dbase text-d16" />
            <span
              className={`flex-1 ${
                props.active === "checkin" && "font-semibold underline"
              }`}
              style={{
                color: props.active === "checkin" ? "rgb(64,69,83)" : "",
              }}
            >
              Check In
            </span>
            <img
              className="-mr-1 group-hover:block hidden"
              src={arrowRight}
              alt=""
            />{" "}
          </Link>
        </li>

        <li
          onMouseEnter={() => {
            setShowBuyArrow(true);
          }}
          onMouseLeave={() => {
            setShowBuyArrow(false);
          }}
        >
          <Link
            to={`${path}/account/buyagain`}
            className="xl:px-10 lg:px-8 md:px-6 px-16 flex gap-4 items-center h-12 w-full hover:text-dblackk"
            style={{ color: "rgb(126,133,155)" }}
          >
            <BsFillCartCheckFill className="text-dbase text-d16" />
            <span
              className={`flex-1 ml-3 ${
                props.active === "buyagain" && "font-semibold underline"
              }`}
              style={{
                color: props.active === "buyagain" ? "rgb(64,69,83)" : "",
              }}
            >
              Buy Again
            </span>
            {showBuyArrow && <img className="-mr-1" src={arrowRight} alt="" />}{" "}
          </Link>
        </li>

        <li
          onMouseEnter={() => {
            setShowRecentlyViewedArrow(true);
          }}
          onMouseLeave={() => {
            setShowRecentlyViewedArrow(false);
          }}
        >
          <Link
            to={`${path}/account/recentlyViewed`}
            className="xl:px-10 lg:px-8 md:px-6 px-16 flex gap-4 items-center h-12 w-full hover:text-dblackk"
            style={{ color: "rgb(126,133,155)" }}
          >
            <MdAvTimer className="text-dbase text-d20" />
            <span
              className={`flex-1 ml-2 ${
                props.active === "recentlyViewed" && "font-semibold underline"
              }`}
              style={{
                color: props.active === "recentlyViewed" ? "rgb(64,69,83)" : "",
              }}
            >
              Recently Viewed
            </span>
            {showRecentlyViewedArrow && (
              <img className="-mr-1" src={arrowRight} alt="" />
            )}{" "}
          </Link>
        </li>
        <li
          onMouseEnter={() => {
            setShowAddArrow(true);
          }}
          onMouseLeave={() => {
            setShowAddArrow(false);
          }}
        >
          <Link
            to={`${path}/account/addresses`}
            className=" ml-1 xl:px-10 lg:px-8 md:px-6 px-16 flex gap-4 items-center h-12 w-full hover:text-dblackk"
            style={{ color: "rgb(126,133,155)" }}
          >
            <i className="icon icon-location text-2xl block w-8 -mr-2 text-dbase"></i>
            <span
              className={`flex-1 ${
                props.active === "addresses" && "font-semibold underline"
              }`}
              style={{
                color: props.active === "addresses" ? "rgb(64,69,83)" : "",
              }}
            >
              Addresses
            </span>
            {showAddArrow && <img className="-mr-1" src={arrowRight} alt="" />}{" "}
          </Link>
        </li>
        <li
          onMouseEnter={() => {
            setShowWishArrow(true);
          }}
          onMouseLeave={() => {
            setShowWishArrow(false);
          }}
        >
          <Link
            to={`${path}/account/wishlist`}
            className="xl:px-10 lg:px-8 md:px-6 px-16 flex gap-4 items-center h-12 w-full hover:text-dblackk"
            style={{ color: "rgb(126,133,155)" }}
          >
            <i className="icon icon-heart text-xl block w-8 text-dbase"></i>
            <span
              className={`flex-1 -ml-1 ${
                props.active === "wishlist" && "font-semibold underline"
              }`}
              style={{
                color: props.active === "wishlist" ? "rgb(64,69,83)" : "",
              }}
            >
              WishList
            </span>
            {showWishArrow && <img className="-mr-1" src={arrowRight} alt="" />}{" "}
          </Link>
        </li>
        <li
          onMouseEnter={() => {
            setShowFollowArrow(true);
          }}
          onMouseLeave={() => {
            setShowFollowArrow(false);
          }}
        >
          <Link
            to={`${path}/account/follow`}
            className=" xl:px-10 lg:px-8 md:px-6 px-16 flex gap-4 items-center h-12 w-full hover:text-dblackk"
            style={{ color: "rgb(126,133,155)" }}
          >
            <RiUserFollowLine className="text-xl block w-5 mr-3 text-dbase" />
            <span
              className={`flex-1 -ml-1 ${
                props.active === "follow" && "font-semibold underline"
              }`}
              style={{
                color: props.active === "follow" ? "rgb(64,69,83)" : "",
              }}
            >
              Follow
            </span>
            {showFollowArrow && (
              <img className="-mr-1" src={arrowRight} alt="" />
            )}{" "}
          </Link>
        </li>

        <li
          onMouseEnter={() => {
            setshowReviewCenterArrow(true);
          }}
          onMouseLeave={() => {
            setshowReviewCenterArrow(false);
          }}
        >
          <Link
            to={`${path}/account/reviewCenter`}
            className=" xl:px-10 lg:px-8 md:px-6 px-16 flex gap-4 items-center h-12 w-full hover:text-dblackk"
            style={{ color: "rgb(126,133,155)" }}
          >
            <BsStarFill className="text-xl block w-5 mr-3 text-dbase" />
            <span
              className={`flex-1 -ml-1 ${
                props.active === "reviewCenter" && "font-semibold underline"
              }`}
              style={{
                color: props.active === "reviewCenter" ? "rgb(64,69,83)" : "",
              }}
            >
              Review Center
            </span>
            {showReviewCenterArrow && (
              <img className="-mr-1" src={arrowRight} alt="" />
            )}{" "}
          </Link>
        </li>

        <li
          onMouseEnter={() => {
            setshowFeedBackArrow(true);
          }}
          onMouseLeave={() => {
            setshowFeedBackArrow(false);
          }}
        >
          <Link
            to={`${path}/account/feedback`}
            className=" xl:px-10 lg:px-8 md:px-6 px-16 flex gap-4 items-center h-12 w-full hover:text-dblackk"
            style={{ color: "rgb(126,133,155)" }}
          >
            <MdFeedback className="text-xl block w-5 mr-3 text-dbase" />
            <span
              className={`flex-1 -ml-1 ${
                props.active === "feedBack" && "font-semibold underline"
              }`}
              style={{
                color: props.active === "feedBack" ? "rgb(64,69,83)" : "",
              }}
            >
              FeedBack
            </span>
            {showFeedBackArrow && (
              <img className="-mr-1" src={arrowRight} alt="" />
            )}{" "}
          </Link>
        </li>

        <li
          onMouseEnter={() => {
            setshowsuggestionArrow(true);
          }}
          onMouseLeave={() => {
            setshowsuggestionArrow(false);
          }}
        >
          <Link
            to={`${path}/account/suggestion`}
            className=" xl:px-10 lg:px-8 md:px-6 px-16 flex gap-4 items-center h-12 w-full hover:text-dblackk"
            style={{ color: "rgb(126,133,155)" }}
          >
            <HiLightBulb className="text-xl block w-5 mr-3 text-dbase" />
            <span
              className={`flex-1 -ml-1 ${
                props.active === "suggestion" && "font-semibold underline"
              }`}
              style={{
                color: props.active === "suggestion" ? "rgb(64,69,83)" : "",
              }}
            >
              Suggestion
            </span>
            {showsuggestionArrow && (
              <img className="-mr-1" src={arrowRight} alt="" />
            )}{" "}
          </Link>
        </li>



      </ul>
    </aside>
  ) : (
    // <div className=" ">
    //   <Link
    //     to={`${path}/account/orders`}
    //     className={`py-2 flex items-center hover:bg-dgrey1 hover:bg-opacity-10 px-3 cursor-pointer ${
    //       props.active === "orders" && "bg-white shadow"
    //     }`}
    //   >
    //     <i className="icon icon-dollar text-xl w-8 "></i>
    //     <span>Orders</span>
    //   </Link>
    //   <Link
    //     to={`${path}/account/addresses`}
    //     className={`py-2 flex items-center hover:bg-dgrey1 hover:bg-opacity-10 px-3 cursor-pointer ${
    //       props.active === "addresses" && "bg-white shadow"
    //     }`}
    //   >
    //     <i className="icon icon-location text-2xl block w-8"></i>
    //     <span>Addresses</span>
    //   </Link>
    //   <Link
    //     to={`${path}/account/wishlist`}
    //     className={`py-2 flex items-center hover:bg-dgrey1 hover:bg-opacity-10 px-3 cursor-pointer ${
    //       props.active === "wishlist" && "bg-white shadow"
    //     }`}
    //   >
    //     <i className="icon icon-heart text-xl block w-8"></i>
    //     <span>WishList</span>
    //   </Link>
    //   {/* <Link to={`${path}/account/notifications`} className={`py-2 flex items-center hover:bg-dgrey1 hover:bg-opacity-10 px-3 cursor-pointer ${props.active === 'notification' && 'bg-white shadow'}`}>
    //             <i className="icon icon-mail text-xl block w-8"></i>
    //             <span>Notifications</span>
    //         </Link> */}
    //   <Link
    //     to={`${path}/account/profile`}
    //     className={`py-2 flex items-center hover:bg-dgrey1 hover:bg-opacity-10 px-3 cursor-pointer ${
    //       props.active === "profile" && "bg-white shadow"
    //     }`}
    //   >
    //     <i className="icon icon-user-solid text-xl w-8 block"></i>
    //     <span>Profile</span>
    //   </Link>
    // </div>
    <div></div>
  );
}

export default UserSidebar;
