import { useLocation } from "react-router-dom";
import { useEffect, useContext } from "react";
import SeoHandler from "../components/SeoHandler";
import ReactPixel from "react-facebook-pixel";
import { pixelID } from "../urls";
import { AccountContext } from "../contexts/AccountContext";

function Success(props) {
  const location = useLocation();
  const data = location.state.data;
  const [accountState] = useContext(AccountContext);
  //purchase event
  useEffect(() => {
    if (!accountState.admin) {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }

      if (window.location.host === "www.ishtari.com") {
        gtag("event", "conversion", {
          send_to: "AW-991347483/CZZzCOys3YwYEJuG29gD",
          value: data?.total,
          currency: "USD",
          transaction_id: data?.orderDetails?.order_id,
        });
      } else if (window.location.host === "www.ishtari.com.gh") {
        gtag("event", "conversion", {
          send_to: "AW-10993907106/ZuLdCKWOl5EYEKLrpvoo",
          value: data?.total,
          currency: "USD",
          transaction_id: data?.orderDetails?.order_id,
        });
      }
    }
  }, [accountState.admin]);


  return (
    <div className="bg-dgreen fixed top-0 left-0 w-screen h-screen flex justify-center items-center text-white flex-col">
      <span className="  w-20 h-20 bg-white flex items-center justify-center mb-4 rounded-full">
        <i className="icon icon-ok text-5xl text-dgreen"></i>
        <SeoHandler data={{ title: "Success " }} />
      </span>
      <div className="flex items-center justify-center w-full">
        <div className=""></div>
        <h1 className="font-bold text-3xl text-center">
          Your Order Has Been Processed
        </h1>
      </div>
      <button
        onClick={() => (window.location.href.split("/")[3]==='bey' ?  window.location.href = "/bey"  : window.location.href = "/")}
        className="bg-white text-dgreen px-10 py-3 rounded mt-4 font-bold"
      >
        CONTINUE SHOPPING
      </button>
    </div>
  );
}

export default Success;
